<template>
  <div class="login-container">
    <div class="centercontain">
      <div class="left">
        <el-image class="backimage" :src="icon2" />
        <div class="logocintain">
          <el-image class="logo" :src="icon6" />
        </div>
      </div>

      <div class="right">
        <div class="logoitem">
          <el-image class="logo" :src="icon1" v-if="schoolid == 1" style="width: 300px;"/>
          <el-image class="logo" :src="icon1_1" v-else />
        </div>
        <!-- <div class="logoitem logoitem2">
          <el-image class="logo" :src="icon7" />
        </div> -->
        <el-form
          ref="loginForm"
          :rules="forgetRules"
          :model="loginForm"
          class="elform"
        >
          <el-form-item
            label=""
            class="elitem"
            prop="mobile"
            style="margin-top: 40px"
          >
            <div class="inputcontain">
              <el-input
                placeholder="请输入手机号"
                v-model="loginForm.mobile"
                type="text"
                class="elinput"
                maxlength="11"
              >
                <template slot="prepend">+86</template>
              </el-input>
              <!-- <el-image class="user" :src="icon5" />
              <el-input
                class="elinput"
                v-model="loginForm.username"
                placeholder="请输入用户名"
                clearable
              >
              </el-input> -->
            </div>
          </el-form-item>
          <el-form-item label="" class="elitem" prop="sms_code">
            <div class="inputcontain_1">
              <el-input
                placeholder="请输入验证码"
                v-model="loginForm.sms_code"
                type="text"
                class="elinput"
              >
                <template slot="append" v-if="show">
                  <div @click="getCode">
                    <span>获取验证码</span>
                  </div>
                </template>
                <template slot="append" v-if="!show">
                  <div @click="getCode" class="getCode">
                    <span>{{ count + "s" }}后重发</span>
                  </div>
                </template>
              </el-input>
              <!-- <el-image class="user" :src="icon5" />
              <el-input
                class="elinput"
                v-model="loginForm.username"
                placeholder="请输入用户名"
                clearable
              >
              </el-input> -->
            </div>
          </el-form-item>
          <el-form-item label="" class="elitem elitem2" prop="password">
            <div class="inputcontain">
              <el-image class="user" :src="icon3" />
              <el-input
                class="elinput"
                v-model="loginForm.password"
                placeholder="请输入密码"
                show-password
                clearable
              >
              </el-input>
            </div>
          </el-form-item>
          <el-form-item label="" class="elitem elitem2" prop="confirm_password">
            <div class="inputcontain">
              <el-image class="user" :src="icon3" />
              <el-input
                class="elinput"
                v-model="loginForm.confirm_password"
                placeholder="确认密码"
                show-password
                clearable
              >
              </el-input>
            </div>
          </el-form-item>
        </el-form>

        <div class="logoitem logbt">
          <el-button class="bt" @click="registerUaserName">注册</el-button>
          <div class="login_name" @click="loginName">
            <span>使用已有账号登录</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getuserinfo, sendsmscode, registerUaserName } from "@/api/user";
import { validUsername } from "@/utils/validate";
import { getschoolinfo } from "@/api/school.js";
import md5 from "js-md5";
import {
  getIdentifierToken,
  setIdentifierToken,
  setWebNameToken,
  setWebAvaterToken,
} from "@/utils/auth";
export default {
  name: "Register",
  components: {},
  data() {
    const pwdCheck = (rule, value, callback) => {
      if (value != this.loginForm3.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const codeCheck = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入正确的验证码!"));
      } else {
        callback();
      }
    };
    // const validateUsername = (rule, value, callback) => {
    //   if (!validUsername(value)) {
    //     callback(new Error("请输入正确手机号"));
    //   } else {
    //     callback();
    //   }
    // };
    // const validatePassword = (rule, value, callback) => {
    //   if (value.length < 6) {
    //     callback(new Error("请输入正确的登录密码"));
    //   } else {
    //     callback();
    //   }
    // };
    return {
      btid: -1,
      dialogVisible: false,
      identifiers: "",
      icon1: require("@/assets/登录/lan-logo.svg"),
      icon1_1: require("@/assets/shifan02.png"),
      icon2: require("@/assets/登录/ujoywzbg.png"),
      icon3: require("@/assets/登录/ujoypwd.png"),
      icon4: require("@/assets/登录/bg1.jpg"),
      icon5: require("@/assets/登录/ujoyuser.png"),
      icon6: require("@/assets/登录/text.png"),
      icon7: require("@/assets/登录/ujoyline.png"),
      checked: "",
      show: true,
      count: "",
      loginForm: {
        mobile: "", // 手机号格式
        password: "",
        confirm_password: "",
        sms_code: "",
      },
      forgetRules: {
        mobile: [
          {
            required: true,
            message: "请输入手机号码",
            trigger: "blur",
          },
          {
            min: 6,
            max: 11,
            message: "手机号码长度不能超过11个字符",
            trigger: "blur",
          },
          {
            pattern: /^[0-9]{6,11}$/,
            message: "请输入正确的手机号码",
          },
          {
            pattern: /^1[3|4|5|6|7|8][0-9]{9}$/,
            message: "请输入正确的手机号码",
          },
        ],
        sms_code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
          {
            required: true,
            validator: codeCheck,
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
          {
            // pattern: /^[0-9A-Za-z]{6,16}$/,
            min: 6,
            max: 16,
            message: "请输入6~16位密码",
          },
        ],
        confirm_password: [
          {
            required: true,
            message: "请输入确认密码",
            trigger: "blur",
          },
          {
            required: true,
            validator: pwdCheck,
            trigger: "blur",
          },
        ],
      },
      passwordType: "password",
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      otherQuery: {},
      schoolid: "",
      schoolinfo: {},
    };
  },

  created() {
    // window.addEventListener('storage', this.afterQRScan)
    this.schoolid = localStorage.getItem("schoolid") * 1 || 1;
    // console.log("login sid", this.schoolid);
    let info = localStorage.getItem("schoolinfo");
    if (info) {
      this.schoolinfo = JSON.parse(info);
    } else {
      getschoolinfo({}).then((res) => {
        if (res && res.code == 0) {
          this.schoolinfo = res.data;
        }
        // console.log("根据学校id获取到的信息", this.schoolinfo);
      });
    }
  },
  mounted() {
    // if (this.loginForm.mobile === "") {
    //   this.$refs.username.focus();
    // } else if (this.loginForm.password === "") {
    //   this.$refs.password.focus();
    // }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    getCode() {
      if (this.loginForm.mobile && this.loginForm.mobile.length === 11) {
        const time = new Date().getTime();
        const params = {
          mobile: this.loginForm.mobile,
          sign: md5(md5(this.loginForm.mobile) + time),
          time: time,
        };
        sendsmscode(params)
          .then((response) => {
            const { data } = response;
            if (response.code === 0) {
              const TIME_COUNT = 60;
              if (!this.timer) {
                this.count = TIME_COUNT;
                this.show = false;
                this.timer = setInterval(() => {
                  if (this.count > 0 && this.count <= TIME_COUNT) {
                    this.count--;
                  } else {
                    this.show = true;
                    clearInterval(this.timer);
                    this.timer = null;
                  }
                }, 1000);
              }
            } else if (response.data && response.data.sms_code) {
              this.loginForm.sms_code = response.data.sms_code;
            } else {
              this.$message({
                message: response.message,
                type: "error",
              });
            }
          })
          .catch((error) => {});
      } else {
        this.$message({
          message: "请输入正确的手机号码!",
          type: "error",
        });
      }
    },
    // 注册
    registerUaserName() {
      if (this.loginForm.sms_code) {
        const params = {
          mobile: this.loginForm.mobile,
          password: this.loginForm.password,
          confirm_password: this.loginForm.confirm_password,
          sms_code: this.loginForm.sms_code,
        };
        registerUaserName(params)
          .then((response) => {
            console.log("response", response);
            if (response.code === 0) {
              this.$message({
                message: "恭喜您，注册成功！！！",
                type: "success",
              });
              this.$router.push({ path: "/login" });
            } else {
              this.$message({
                message: "短信验证码错误!",
                type: "error",
              });
              // 错误后验证码置空
              this.loginForm.sms_code = "";
              this.codeCheck();
            }
          })
          .catch((error) => {});
      }else{
        this.$message({
          message: '请先获取验证码!',
          type: 'error'
        })
      }
    },
    loginName() {
      this.$router.push({
        path: "/login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  // border: 1px solid red;
  height: 100%;
  width: 100%;
  position: relative;
  background: url("../../assets/登录/bg1.jpg") 0 0 no-repeat;
  background-size: 100% 100%;
  // overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  .centercontain {
    // border: 1px solid red;
    width: 50%;
    // height: 685px;
    display: flex;
    .left {
      position: relative;
      width: 481px;
      .logocintain {
        // border: 1px solid red;
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        .logo {
        }
      }
    }
    .right {
      flex: 1;
      background: #ffffff;
      .logoitem {
        margin-top: 110px;
        display: flex;
        justify-content: center;
        align-items: center;
        .inputcontain {
          width: 411px;
          height: 60px;
          border: 1px solid #3D84FF;
          border-radius: 10px;
          padding: 0px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .user {
            margin-left: 20px;
            // margin-right: 4px;
          }
          .elinput {
            ::v-deep .el-input__inner {
              border: none;
            }
          }
        }
      }
      .logoitem2 {
        margin-top: 78px;
      }
      .elform {
        .elitem {
          // border: 1px solid red;
          margin-top: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          .inputcontain {
            width: 411px;
            height: 60px;
            border: 1px solid #3D84FF;
            border-radius: 10px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .user {
              // border: 1px solid red;
              margin-left: 20px;
              // margin-right: 4px;
              width: 27px;
              height: 27px;
            }
            .elinput {
              // border: 1px solid red;
              ::v-deep .el-input__inner {
                border: none;
                height: 60px;
                border-radius: 0px 10px 10px 0px;
              }
            }
            ::v-deep .el-input-group__prepend {
              border: none;
              color: #000;
              height: 60px;
              border-radius: 10px 0 0 10px;
            }
          }
          .inputcontain_1 {
            width: 411px;
            height: 60px;
            border: 1px solid #3D84FF;
            border-radius: 10px;
            padding: 0px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .user {
              // border: 1px solid red;
              margin-left: 20px;
              // margin-right: 4px;
              width: 27px;
              height: 27px;
            }
            ::v-deep .el-input-group__append {
              border: none;
              color: #000;
              height: 60px;
              border-radius: 0px 10px 10px 0px;
              cursor: pointer;
              width: 60px;
              text-align: center;
            }
            ::v-deep .el-input__inner {
              border: none;
              height: 60px;
              border-radius: 10px 0px 0px 10px;
            }
            .getCode {
              color: #3D84FF;
            }
          }
        }
        .elitem2 {
          margin-top: 31px;
        }
      }
      .passtip {
        margin-top: 10px;
        .contain {
          width: 411px;
          height: 30px;
          // border: 1px solid #26b5ff;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding-left: 14px;
          padding-right: 14px;
        }
      }
      .logbt {
        margin-top: 35px;
        .bt {
          width: 200px;
          height: 60px;
          color: white;
          background: #3D84FF;
          // border: 1px solid #32a4db;
          border-radius: 10px;
        }
        .login_name {
          margin-left: 50px;
          color: #3D84FF;
          cursor: pointer;
        }
      }
    }
  }
  .my_eldialog {
    .dialog_con {
      width: 100%;
      height: 340px;

      .title_t {
        text-align: center;
        font-size: 22px;
        font-weight: bold;
        color: #3D84FF;
      }
      .icon_con {
        margin: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        //  border: 1px solid red;
        .item_con {
          margin-right: 20px;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .el_icon {
            position: relative;
            width: 80px;
            height: 80px;
            .img_icon {
              position: absolute;
              top: 0px;
            }
          }
          .text_t {
            margin-top: 10px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
          }
        }
      }
      .bt_con {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        .bt {
          border-radius: 20px;
          width: 220px;
          height: 50px;
        }
      }
    }
  }
}
::v-deep .el-dialog {
  box-shadow: 1px 0px 6px 2px #ccc !important;
  border-radius: 10px !important;
}
::v-deep .el-dialog__header {
  padding: 0px;
}
</style>

